<template>
	<!-- <navs></navs> -->
	<!-- navs -->
	<div class="banner_top" v-show="bannershow && bannershow['OTHER_TOP'] == '1'">
		<banner :type="0" abs="OTHER_TOP"></banner>
	</div>
	<!-- navs -->
	<Btn cd="ph"></Btn>
	<div class="lf_footer1">
		<el-row>
			<el-col :xs="24" :sm="24" :md="24">
				<div class="lf_footer1_tit_box">
					<div class="lf_foot_tit_text1">{{langs["Players & Stats"]}}</div>
					<div class="lf_foot_tit_line"></div>
					<div class="lf_foot_tit_text2">{{langs["Players & Stats_en"]}}</div>
				</div>
			</el-col>

		</el-row>
	</div>


	<div class="head">
		<div class="head_top">
			<div class="searchimg">
				<img src="../../assets/images/u6324.png" />
			</div>
			<div class="searchbox" style="position: relative;">
				<input v-model="searchkey" @keyup.enter="search(searchkey,'input')" placeholder="" type="text" />
				<img @click="searchM()" v-if="searchkey!=''" style="width: 0.3rem;position: absolute;right: 1.6rem;top: 0.1rem;z-index: 9;" src="../../assets/images/u21104.png" alt="" />
				<div class="searchbutton hand" @click="search(searchkey,'input')">{{langs.psearch_search}}</div>
			</div>
			<div class="search hand">
				<div class="search_pos">
					<span class="kindbox" @click.stop="changeazshow">
						{{langs.psearch_seaz}}
					</span>
					<img src="../../assets/images/u182.png" @click.stop="changeazshow" />
					<div class="select-back" v-if="azshow" @click.stop="changeazshow"></div>
					<div class="select-right into" v-if="azshow">
						<div class="select_title">{{langs.psearch_seaz}}</div>
						<div class="select_socl">
							<div class="select_item " v-for="item in w" @click="search(item,'leter')">{{item}}
								<!-- <div class="close_search">×</div> -->
							</div>
						</div>

					</div>
				</div>

			</div>
		</div>
		<div class="searchword">{{langs.psearch_contentdesc}}</div>
	</div>
	<div class="all_body">
		<div class="leftbox">
			<div class="changebox" style="margin-top: 0;">
				<div class="changekind">
					<div class="lf_match_info_box">
						<div class="d_title">{{langs["ROLEX RANKINGS"]}} | <span
								class="e_title">{{langs["ROLEX RANKINGS_en"]}}</span></div>
					</div>
				</div>
				<router-link target="_blank" :to="{path:'/Rankinfo',query:{ranktype:'laolishi',lang:systemlang}}">
					<div class="changetime">
						<span class="timeword">
							{{langs.chakanquanbu}}
						</span>
						<img src="../../assets/images/u182.png" />
					</div>
				</router-link>
			</div>
			<div class="peoplebox" v-if="laolishirank!=undefined&&laolishirank.length>0">
				<div class="leftpeople">
					<router-link target="_blank"
						:to="{path:'/playerInfo',query:{pl_id:laolishirank[0].id,lang:systemlang}}">
						<div class="peopleinfo">
							<div class="peoplenum">{{laolishirank[0].rank}}</div>
							<div class="peopleall">
								<div class="peopleimg">
									<img :src="'http://clpga.org/'+laolishirank[0].pl_photo" />
								</div>
								<div class="peopleword">
									<div class="peoplename hoverUnderLine">
										<img :src="laolishirank[0].pl_country_img" alt="" />
										<span
											style="font-weight: bold;margin-left: 0.1rem;">{{laolishirank[0].cn_name}}<span>{{laolishirank[0].type}}</span></span>
									</div>
									<div class="peoplenameSmall hoverUnderLine">
										{{laolishirank[0].en_name}}
									</div>
								</div>
							</div>
						</div>
					</router-link>
					<div class="numinfo">
						<div class="numtitle">{{langs.pingjunjifen}}</div>
						<div class="numword">{{laolishirank[0].score}}</div>
					</div>
					<div class="numinfo">
						<div class="numtitle">{{langs.cansaichangci}}</div>
						<div class="numword">{{laolishirank[0].events}}</div>
					</div>
					<div class="numinfo">
						<div class="numtitle">{{langs.shangzhoupaiming}}</div>
						<div class="numword">{{laolishirank[0].rank-laolishirank[0].change}}</div>
					</div>
				</div>
				<div class="leftpeople">
					<div class="lefttop">
						<div class="topwordp">{{langs.paiming}}</div>
						<div class="topwordq">{{langs.text_player}}</div>
						<div class="topwordc">{{langs.pingjunjifen}}</div>
						<div class="topwordc">{{langs.cansaichangci}}</div>
					</div>
					<template v-for="(item,index) in laolishirank">
						<router-link target="_blank" :to="{path:'/playerInfo',query:{pl_id:item.id,lang:systemlang}}">
							<div class="leftdown" v-if="index>=1">
								<div class="topwordpdown">{{item.rank}}</div>
								<div class="topwordqdown">
									<div class="peoplenameright hoverUnderLine">
										<img :src="item.pl_country_img" alt="" />
										<span class="hoverUnderLine"
											style="font-weight: bold;float: left;margin-left: 0.1rem;">{{item.cn_name}}<span>{{item.type}}</span></span>

									</div>
									<div class="peoplenameright hoverUnderLine" style="text-align: left;font-size: 0.24rem;">
										{{item.en_name}}
									</div>
								</div>
								<div class="topwordcdown" style="font-weight: bold;">{{item.score}}</div>
								<div class="topwordcdown">{{item.events}}</div>
							</div>
						</router-link>
					</template>
					<!-- 		<div class="leftdown">
						<div class="topwordpdown">2</div>
						<div class="topwordqdown">
							<div class="peoplenameright">
								<img src="../../assets/images/u33.png" alt="" />
								<span style="font-weight: bold;float: left;margin-left: 0.3rem;">林希妤</span>
							</div>
							<div class="peoplenameright" style="text-align: left;">
								Xiyu LIN
							</div>
						</div>
						<div class="topwordcdown" style="font-weight: bold;">3.14</div>
						<div class="topwordcdown">11</div>
					</div> -->
				</div>

			</div>

			<div class="banner" v-show="bannershow && bannershow['PLAYER_LAOLISHI_BOTTOM'] == '1'">
				<banner heightNew="1.22rem" :type="2" abs="PLAYER_LAOLISHI_BOTTOM"></banner>
			</div>

			<!-- 奖金榜开始 -->
			<div class="changebox">
				<div class="changekind">
					<div class="lf_match_info_box">
						<div class="d_title">{{langs["Order of Merit"]}} | <span>{{langs["Order of Merit_en"]}}</span>
						</div>
					</div>
				</div>
				<router-link target="_blank"
					:to="{path:'/Rankinfo',query:{ranktype:'prizerank',lang:systemlang,'year':prizzeyear}}">
					<div class="changetime">
						<span class="timeword">
							{{langs.chakanquanbu}}
						</span>
						<img src="../../assets/images/u182.png" />
					</div>
				</router-link>
			</div>
			<div class="peoplebox">
				<div class="leftpeople" v-if="prizelist&&prizelist[0]">
					<router-link target="_blank"
						:to="{path:'/playerInfo',query:{pl_id:prizelist[0].pl_id,lang:systemlang}}">
						<div class="peopleinfo">
							<div class="peoplenum">{{prizelist[0].rank}}</div>
							<div class="peopleall">
								<div class="peopleimg">
									<img :src="prizelist[0].pl_photo_img" />
								</div>
								<div class="peopleword">
									<div class="peoplename hoverUnderLine">
										<img :src="prizelist[0].pl_country_img" alt="" />

										<span
											style="font-weight: bold;margin-left: 0.1rem;">{{prizelist[0].cn_name}}<span>{{prizelist[0].type}}</span></span>

									</div>
									<div class="peoplenameSmall hoverUnderLine">
										{{prizelist[0].en_name}}
									</div>
								</div>
							</div>
						</div>
					</router-link>
					<div class="numinfo">
						<div class="numtitle">{{langs.text_price_sum}}</div>
						<div class="numword">{{prizelist[0].score}}</div>
					</div>
					<div class="numinfo">
						<div class="numtitle">{{langs.jinjichangci}}</div>
						<div class="numword">{{prizelist[0].match_join_count}}{{langs.chang}}</div>
					</div>
					<div class="numinfo">
						<div class="numtitle">{{langs.cansaichangci}}</div>
						<div class="numword">{{prizelist[0].count_match}}{{langs.chang}}</div>
					</div>
					<div class="numinfo line">
						<div class="numtitle">{{langs["duoguancishu"]}}</div>
						<div class="numword">{{prizelist[0].playernum.top1num}}{{langs.ci}}</div>
					</div>
					<div class="numinfo">
						<div class="numtitle">{{langs["top10num"]}}</div>
						<div class="numword">{{prizelist[0].playernum.top10num}}{{langs.ci}}</div>
					</div>
					<div class="numinfo">
						<div class="numtitle">{{langs["pingjunganshu"]}}</div>
						<div class="numword">{{parseFloat(prizelist[0].playernum.perscore).toFixed(1)}}</div>
					</div>
				</div>
				<div class="leftpeople">
					<div class="lefttop">
						<div class="topwordp">{{langs.paiming}}</div>
						<div class="topwordq">{{langs.text_player}}</div>
						<div class="topwordc">{{langs.text_price}}</div>
						<div class="topwordc" v-if="systemlang=='cn'">{{langs.Rounds}}/{{langs.Rounds1}}</div>
						<div class="topwordc" v-else>Events</div>
					</div>
					<template v-for="(item,index) in prizelist">
						<router-link target="_blank"
							:to="{path:'/playerInfo',query:{pl_id:item.pl_id,lang:systemlang}}">
							<div class="leftdown" style="height: 1.15rem;" v-if="index>=1">
								<div class="topwordpdown">{{item.rank}}</div>
								<div class="topwordqdown">
									<div class="peoplenameright hoverUnderLine">
										<img :src="item.pl_country_img" alt="" />
										<span class="hoverUnderLine"
											style="font-weight: bold;float: left;margin-left: 0.1rem;">{{item.cn_name}}<span>{{item.type}}</span></span>

									</div>
									<div class="peoplenameright hoverUnderLine" style="text-align: left; font-size: 0.24rem;">
										{{item.en_name}}
									</div>
								</div>
								<div class="topwordcdown" style="font-weight: bold;">{{item.score}}</div>
								<div class="topwordcdown">{{item.match_join_count}}/{{item.count_match}}</div>
							</div>
						</router-link>
					</template>
				</div>

			</div>
			<div class="banner" v-show="bannershow && bannershow['PLAYER_CLPGA_BOTTOM'] == '1'">
				<banner heightNew="1.22rem" :type="2" abs="PLAYER_CLPGA_BOTTOM"></banner>
			</div>
			<div class="changebox">
				<div class="changekind">
					<div class="lf_match_info_box">
						<div class="d_title">{{langs["CGA RANKINGS"]}} | <span>{{langs["CGA RANKINGS_en"]}}</span></div>

					</div>
				</div>
				<a href="https://www.cgagolf.org.cn/all_ranking.html" target="_blank">
					<div class="changetime">
						<span class="timeword">
							{{langs["chakangengduo"]}}
						</span>
						<img src="../../assets/images/u182.png" />
					</div>
				</a>
			</div>
			<div class="lookmore">
				{{langs["gotovisit"]}}
			</div>
			<div class="banner" v-show="bannershow && bannershow['PLAYER_ZGX_BOTTOM'] == '1'">
				<banner heightNew="1.22rem" :type="2" abs="PLAYER_ZGX_BOTTOM"></banner>
			</div>
			<div class="changebox" v-if="zgposfile.fileurl">
				<div class="changekind">
					<div class="lf_match_info_box">
						<div class="d_title">{{langs["EXEMPT ORDER"]}} | <span>{{langs["EXEMPT ORDER_en"]}}</span></div>
					</div>
				</div>
			</div>
			<div class="lookmore" v-if="zgposfile.fileurl">
			<div class="sort">
				<span style="font-weight: bold;">{{zgposfile.title}}</span><!-- 2023年女子中巡参赛资格及次序 -->
				<div class="sortbutton">
					<a :href="zgposfile.fileurl" target="_blank" :download="zgposfile.title+zgposfile.filetype">
						<div class="sortleft"><img src="../../assets/images/u19547.png" alt="" /></div>
						<span style="float: left;color: #fff;margin-left: 0.1rem;">PDF文件 <span
								style="margin-left: 0.2rem;color: #fff;">|</span></span>
						<div class="sortlast"><img src="../../assets/images/u19541.png" alt="" /></div>

					</a>
				</div>
				<div class="sortbuttonsmall">
					<a :href="zgposfile.fileurl" target="_blank" :download="zgposfile.title+zgposfile.filetype">
						<div class="sortleft"><img src="../../assets/images/u19547.png" alt="" /></div>
					</a>
				</div>
			</div>
			</div>
			<div class="banner" v-if="zgposfile.fileurl" v-show="bannershow && bannershow['PLAYER_ZGPXU_BOTTOM'] == '1'">
				<banner heightNew="1.22rem" :type="2" abs="PLAYER_ZGPXU_BOTTOM"></banner>
			</div>
		</div>
		<div class="rightbox">
			<!-- <div class="rightinfo">
				<template v-for="item in list">
					<div class="itembox">
						<div class="itemtitle">
							<span class="itemtitleleft">前十次数</span>
							<span class="itemtitleright">TOP 10 FINISHES</span>
							<div class="itemrightpng"><img src="../../assets/images/u182.png" /></div>
						</div>
						<div class="itemtitleword">次数/参赛场</div>
						<div class="itemallbox">
							<div class="cup">
								<img src="../../assets/images/u19404.png" />
							</div>
							<div class="peoplewordtop">
								<div class="peoplenametop">
									<img src="../../assets/images/u33.png" alt="" />
									<span style="font-weight: bold;margin-left: 0.2rem;">林希妤</span>
								</div>
								<div class="peoplenametop">
									Xiyu LIN
								</div>
							</div>
							<div class="itemnum">40</div>
						</div>
					</div>
				</template>
			</div> -->
			<div class="rightinfo">
				<template v-for="(item,index) in list">
					<div class="bannerright" v-if="index==3"
						v-show="bannershow && bannershow['PLAYER_STATS_CENTER'] == '1'">
						<banner heightNew="1.2rem" :type="2" abs="PLAYER_STATS_CENTER"></banner>
					</div>

					<div class="itembox">
						<router-link target="_blank"
							:to="{path:'/TecStatisticsinfo',query:{statstype:item.type,lang:systemlang,'year':year}}">
							<div class="itemtitle">
								<span class="itemtitleleft">{{item.titleleft}}</span>
								<span class="itemtitleright">{{item.titleright}}</span>
								<div class="itemrightpng"><img src="../../assets/images/u182.png" /></div>
							</div>
						</router-link>
						<div class="itemtitleword">{{index==0?(langs.times+"/"+item.field[1]):""}}</div>
						<div class="itemallbox">
							<div class="cup">
								<img src="../../assets/images/u19404.png" />
							</div>
							<div class="peoplewordtop" v-if="item.first.pl_id!=undefined">
								<router-link target="_blank"
									:to="{path:'/playerInfo',query:{pl_id:item.first.pl_id-200000,lang:systemlang}}">
									<div class="peoplenametop">
										<img :src="item.first.pl_country_img" alt="" />
										<span class="hoverUnderLine"
											style="font-weight: bold;margin-left: 0.1rem;">{{item.first.pl_cn_name}}{{item.first.pl_type=="AMA"?"(A)":""}}</span>

									</div>
									<div class="peoplenametop hoverUnderLine" style="font-size: 0.24rem;">
										{{item.first.pl_en_name}}
									</div>
								</router-link>
							</div>
							<div class="itemnum" v-if="item.first.pl_id!=undefined">
								<span v-if="'getfronttennum'==item.type||'getbirdienum'==item.type||'geteaglenum'==item.type">{{item.first.num}}</span>
								<span v-else>{{item.first.num.toFixed(1)}}</span>
								<span v-if="'getqiudaolv'==item.type||'getpargreenlv'==item.type||'getgreensavenum'==item.type||'getsandsavenum'==item.type">%</span>
								<span>{{index!=0?"":("/"+item.first.matchnum)}}</span>
							</div>
						</div>
					</div>

				</template>
			</div>
		</div>
	</div>


	<Footer></Footer>
	<Footer1></Footer1>
</template>

<script>
	// @ is an alias to /src
	import banner from "../../components/banner.vue";
	import navs from "../../components/nav.vue";
	import Btn from "../../components/Btn.vue";
	import Footer from "../../components/Footer.vue";
	import Footer1 from "../../components/Footer1.vue";
	import axios from '../../axios/request';
	import {
		getSystemLang,
		getLangAll
	} from "../../lang";
	//这里就是更新后的路径，以前是import { Swiper, SwiperSlide } from 'swiper/vue'
	import {
		Swiper,
		SwiperSlide
	} from 'swiper/vue/swiper-vue';

	import {
		Autoplay,
		Pagination
	} from 'swiper';
	import 'swiper/swiper-bundle.css';
	import 'swiper/swiper.min.css';
	//这里就是更新后的路径，以前是import 'swiper/css'

	var enddata = (new Date()).getFullYear();
	var years = [];
	for (var i = enddata; i >= 2004; i--) {
		years.push({
			value: i,
			label: i
		});
	}


	//自动轮播的配置
	export default {
		data() {
			return {
				activeNames: ['1'],
				langs: '',
				systemlang: 'cn',
				input: '',
				value: '',
				years: years,
				year: '',
				matchs: [],
				mt_id: '',
				pl_name: '',
				banner: {},
				wonderful: {},
				xhs: {},
				match: {},
				//xhs:巡回赛新闻 match:赛站新闻
				sglist: [],
				laolishirank: [],
				prizelist: [],
				rightnav: [],
				pyear: (new Date()).getFullYear(),
				curyear: (new Date()).getFullYear(),
				prizzeyear: (new Date()).getFullYear(),
				list: [],
				zgposfile: {},
				searchkey: "",
				w: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T',
					'U', 'V', 'W', 'X', 'Y', 'Z'
				],
				azshow: false,
				bannershow: {}
			}
		},
		props: {

		},
		watch: {

		},
		name: 'Home',
		components: {
			navs,
			Btn,
			Footer,
			Footer1,
			Swiper,
			SwiperSlide,
			banner
		},
		created() {
			this.systemlang = getSystemLang();
			this.langs = getLangAll(this.systemlang);
			var year = (new Date()).getFullYear();
			this.year = year;
			axios('sgrank/getlaolishirank.json', {
					year: year
				})
				.then((res) => {
					var laolishirank = res.data;
					if (laolishirank.length == 0) {
						this.laolishirank = laolishirank;
					} else {
						var laolishirank5 = [];
						for (var key in laolishirank) {
							if (laolishirank5.length < 3) {
								if (getSystemLang() == "cn") {
									laolishirank[key]["cn_name"] = laolishirank[key]["cn_name"];
									laolishirank[key]["en_name"] = laolishirank[key]["en_name"];
								} else {
									var temp = laolishirank[key]["cn_name"];
									laolishirank[key]["cn_name"] = laolishirank[key]["en_name"];
									laolishirank[key]["en_name"] = temp;
								}
								laolishirank5.push(laolishirank[key]);
							} else {
								break;
							}
						}
						this.laolishirank = laolishirank5;
					}
				});
			axios('sgrank/getprizerank.json', {
					year: this.pyear
				})
				.then((res) => {
					var prizelist = res.data;
					// console.log(res.data,'prize')
					if (res.data.data && res.data.data.length < 1) {
						this.pyear = this.pyear-1;
						axios('sgrank/getprizerank.json', {
								year: this.pyear
							})
							.then((res) => {
								var prizelist = res.data;
								this.prizzeyear = this.pyear;
								if (prizelist.length == 0) {
									this.prizelist = prizelist;
								} else {
									var prizelist5 = [];
									for (var key in prizelist) {
										if (prizelist5.length < 5) {
											if (getSystemLang() == "cn") {
												prizelist[key]["cn_name"] = prizelist[key]["cn_name"];
												prizelist[key]["en_name"] = prizelist[key]["en_name"];
											} else {
												var temp = prizelist[key]["cn_name"];
												prizelist[key]["cn_name"] = prizelist[key]["en_name"];
												prizelist[key]["en_name"] = temp;
											}
											prizelist5.push(prizelist[key]);
										} else {
											break;
										}
									}
									this.prizelist = prizelist5;
								}
							});
						return false;
					}
					// console.log(prizelist);
					if (prizelist.length == 0) {
						this.prizelist = prizelist;
					} else {
						var prizelist5 = [];
						for (var key in prizelist) {
							if (prizelist5.length < 5) {
								// if(prizelist[key]["pl_photo"]!=""&&prizelist[key]["pl_photo"]!=undefined){
								// 	if(prizelist[key]["pl_photo"].indexOf("uploadfile")!=-1){
								// 		prizelist[key]["pl_photo"] = "http://www.clpga.org/"+prizelist[key]["pl_photo"]
								// 	}else{
								// 		prizelist[key]["pl_photo"] = "http://www.wifigolf.com/club/adm/images/players/"+prizelist[key]["pl_photo"];
								// 	}
								// }
								if (getSystemLang() == "cn") {
									prizelist[key]["cn_name"] = prizelist[key]["cn_name"];
									prizelist[key]["en_name"] = prizelist[key]["en_name"];
								} else {
									var temp = prizelist[key]["cn_name"];
									prizelist[key]["cn_name"] = prizelist[key]["en_name"];
									prizelist[key]["en_name"] = temp;
								}
								prizelist5.push(prizelist[key]);
							} else {
								break;
							}
						}
						this.prizelist = prizelist5;
					}
				});
			this.getlist(year);
			axios('sgrank/getzgposfile.json')
				.then((res) => {
					this.zgposfile = res.data.data;
				})
			document.body.addEventListener('click', (e) => {
				// console.log(e.target)
				this.azshow = false;
				this.unlock()
			});
		},
		setup() {
			const onSwiper = (swiper) => {
				console.log(swiper);
			};
			const onSlideChange = () => {
				// console.log('slide change123123123', e.activeIndex);
			};
			const autoplayOptions = {
				delay: 5000,
				disableOnInteraction: true,
				loop: false,
				pauseOnMouseEnter: true,
				reverseDirection: true
			};
			return {
				onSwiper,
				onSlideChange,
				autoplayOptions,
				modules: [Pagination, Autoplay],
			};
		},
		methods: {
			search: function(searchkey, type) {
				if (searchkey == "") {
					alert(this.systemlang == 'cn' ? '请输入内容' : "Please enter content");
					return;
				}
				// PlayerSearch?lang=cn&leter=B&searchkey=B&page=1
				if (type == "leter") {
					this.changeazshow();
					var leter = searchkey;
					searchkey = "";
				} else {
					var leter = ""
				}
				var params = {
					lang: getSystemLang(),
					leter: leter,
					searchkey: searchkey
				}
				let routeUrl = this.$router.resolve({
					path: "/PlayerSearch",
					query: params
				});
				// console.log(routeUrl.href);
				window.open(routeUrl.href, '_blank');
			},
			getlist: function(year) {
				var that = this;
				axios('tecstatistics/getstatslist', {
						year: year
					})
					.then((res) => {
						var list = res.data.data.data;
						this.year = list[0].year;
						for (var key in list) {
							list[key].first = {};
							list[key].rank2to5 = [];
							for (var k in list[key].list) {
								if (getSystemLang() == "cn") {
									list[key].list[k]["pl_cn_name"] = list[key].list[k]["pl_cn_name"];
									list[key].list[k]["pl_en_name"] = list[key].list[k]["pl_en_name"];
								} else {
									var temp = list[key].list[k]["pl_cn_name"];
									list[key].list[k]["pl_cn_name"] = list[key].list[k]["pl_en_name"];
									list[key].list[k]["pl_en_name"] = temp;
								}
								if (k == 0) {
									list[key].first = list[key].list[k];
								}
								if (k >= 1 && k <= 4) {
									list[key].rank2to5.push(list[key].list[k]);
								}
								if (k > 4) {
									break;
								}
							}
							if (getSystemLang() != "cn") {
								var temptitle = list[key].titleleft;
								list[key].titleleft = list[key].titleright;
								list[key].titleright = temptitle;
							}
						}
						console.log("list",list);
						this.list = list;
					})
			},
			unlock(){
				document.body.style.overflow = 'unset'
			},
			searchM:function()
			{
				this.searchkey = '';
			},
			setbannershow(abs, isshow) {
				// console.log(abs,isshow);
				this.bannershow[abs] = isshow;
			},
			changeazshow(){
				this.azshow=!this.azshow
				var width = document.body.clientWidth;
				if(this.azshow==true && width<=750){
					document.body.style.overflow = 'hidden'
				}else{
					document.body.style.overflow = 'unset'
				}
			}
		}

	}
</script>


<style scoped>
	.sortbuttonsmall {
		display: none;
	}

	.changebox{
		margin-top: 0.5rem;
	}
	.leftbox {
		width: 12.2rem;
		overflow: hidden;
		float: left;
	}

	.rightbox {
		width: 6rem;
		overflow: hidden;
		float: right;

	}

	.rightinfo {
		overflow: hidden;
		width: 6rem;
		background-image: linear-gradient(to bottom, #fafafa, #fff);
		padding-bottom: 0.2rem;

	}

	.itembox {
		width: 5.5rem;
		height: 2rem;
		border-left: 0.06rem solid #C0153E;
		margin-top: 0.2rem;
		margin-left: 0.25rem;
		background-image: linear-gradient(to right, #fff, #FFF1F2);
		border-radius: 0 0.1rem 0.1rem 0;
		box-shadow: 0 0.05rem 0.05rem rgba(138, 138, 138, 0.203921568627451);
		overflow: hidden;
		position: relative;
	}

	.itemtitle {
		height: 0.33rem;
		width: 5.5rem;
		line-height: 0.33rem;
		margin-top: 0.1rem;

	}

	.line {
		border-top: 0.01rem solid rgba(239, 239, 239);
		padding-top: 0.1rem;
	}

	.itemtitleword {


		font-size: 0.2rem;
		color: #999999;
		position: absolute;
		right: 0.1rem;
		letter-spacing: 0.02rem;
		top: 0.6rem;
		margin-right: 0.24rem;
		z-index: 2;

	}

	.itemtitleleft {
		margin-left: 0.3rem;
		font-size: 0.3rem;
		color: #333333;
	}

	.itemtitleright {
		margin-left: 0.1rem;
		font-size: 0.2rem;
		color: #666666;
	}

	.itemrightpng {
		width: 0.3rem;
		height: 0.3rem;
		float: right;

		margin-right: 0.3rem;

	}

	.itemrightpng img {
		width: 0.3rem;
		height: 0.3rem;
	}

	.itemallbox {
		width: 5.5rem;
		height: 1rem;
		margin-top: 0.4rem;

	}

	.cup {
		width: 1rem;
		height: 1rem;
		margin-left: 0.3rem;
		float: left;
		
	}

	.cup img {
		width: 1rem;
		height: 1rem;
	}



	.lookmore {
		width: 11.8rem;
		height: 1.1rem;
		line-height: 0.7rem;
		font-size: 0.26rem;
		color: #666666;
		border-bottom: 1px solid rgba(239, 239, 239);
		margin: 0.4rem;
		padding-bottom: 0.4rem;
	}

	.sort {
		width: 11.8rem;
		height: 0.7rem;
		line-height: 0.7rem;
		font-size: 0.26rem;
		margin-top: 0.2rem;
	}

	.sort span {
		color: #333333;
		font-size: 0.3rem;
	}

	.sortbutton {
		width: 3rem;
		height: 0.7rem;
		background-color: #C0153E;
		border-radius: 0.04rem;
		float: right;
		color: #fff;
		display: flex;
		align-items: center;

	}
	.sortbutton a{
		display: flex;
		align-items: center;
	}
	.sortbutton span {
		font-size: 0.26rem !important;
	}

	.sortlast {
		width: 0.5rem;
		height: 0.5rem;
		margin-left: 0.2rem;
		float: left;
		display: flex;
		align-items: center;

	}

	.sortlast img {
		width: 0.5rem;
		height: 0.5rem;
	}

	.sortleft {
		width: 0.4rem;
		height: 0.4rem;
		margin-left: 0.1rem;
		float: left;
		display: flex;
		align-items: center;
	}

	.sortleft img {
		width: 0.4rem;
		height: 0.4rem;


	}

	.peoplebox {
		width: 100%;
		overflow: hidden;

		border-bottom: 1px solid rgba(239, 239, 239);
		padding-bottom: 0.4rem;
		margin-top: 0.1rem;
		margin-bottom: 0.6rem;
	}

	.leftpeople {
		width: 6rem;
		float: left;
	}

	.numinfo {
		overflow: hidden;
		width: 4.2rem;
		margin-top: 0.1rem;
		margin-left: 1rem;
		display: flex;
		align-items: center;
		justify-content: space-between;
		background-color: #f9f9f9;
		border-bottom: 1px solid #efefef;
		height: 0.6rem;
	}

	.numtitle {


		font-size: 0.26rem;
		color: #666666;
		float: left;
		margin-left: 0.1rem;
	}

	.numword {

		font-size: 0.28rem;
		color: #333333;
		float: right;
		text-align: right;
		font-weight: bold;
		margin-right: .1rem;
	}

	.peopleinfo {

		width: 6rem;
		height: 1.5rem;

	}

	.peoplenum {
		width: 1rem;
		height: 1.3rem;
		text-align: center;
		color: #C0153E;
		font-size: 0.4rem;
		float: left;
		font-weight: bold;
	}

	.peopleall {

		float: left;
		width: 4.2rem;
		height: 1.5rem;
	}

	.peopleimg {
		width: 1.2rem;
		height: 1.4rem;
		float: left;
	}

	.peopleimg img {
		width: 1.2rem;
		height: 1.4rem;
	}

	.peopleword {
		width: 3rem;
		height: 1.4rem;
		float: left;

	}

	.peoplename {
		width: 2.7rem;
		height: 0.6rem;
		margin-left: 0.3rem;
		font-size: 0.28rem;
		display: flex;
		align-items: center;

	}

	.people_name_text {
		width: 2.1rem;


		line-height: 0.28rem;
		margin-top: 0.16rem;

		font-weight: bold;
		margin-left: 0.2rem;
		float: left;
	}

	.peoplename img {
		width: 0.4rem;

		float: left;

	}

	.peoplenameSmall {
		width: 2.5rem;

		line-height: 0.28rem;
		margin-left: 0.3rem;
		font-size: 0.24rem;
		margin-top: 0.2rem;

	}

	.peoplenameSmall img {
		width: 0.36rem;
		height: 0.3rem;
		float: left;
		margin-top: 0.15rem;
	}

	.peoplewordtop {
		width: 2.5rem;
		height: 1.2rem;
		float: left;

	}

	.peoplenametop {
		width: 2.5rem;
		height: 0.6rem;
		margin-left: 0.3rem;
		font-size: 0.28rem;
		display: flex;
		align-items: center;
		position: relative;
		/* 	line-height: 0.6rem; */

	}

	.peoplenametop span {
		display: flex;
		align-items: center;
		width: 3rem;
		flex-shrink: 0;
	}

	.peoplenametop img {
		width: 0.4rem;
		flex-shrink: 0;
	}

	.itemnum {
		width: 1.6rem;
		height: 1rem;
		line-height: 1rem;
		color: #C0153E;
		font-size: 0.3rem;
		text-align: center;
		float: left;
		font-weight: bold;
	}

	.peoplenameright {
		width: 2.5rem;
		height: 0.4rem;
		line-height: 0.4rem;
		margin-left: 0.3rem;
		font-size: 0.28rem;
	}

	.peoplenameright img {
		width: 0.4rem;

		float: left;
		margin-top: 0.07rem;
	}

	.rightpeople {
		width: 6.3rem;
		float: left;
	}



	.lefttop {
		width: 6.3rem;
		height: 0.3rem;
		line-height: 0.3rem;
	}

	.leftdown {
		width: 6.3rem;
		height: 1.43rem;
		line-height: 0.8rem;
		border-bottom: 1px solid #efefef;
		margin-top: 0.2rem;
		background-color: #f9f9f9;
		display: flex;
		align-items: center;
	}

	.topwordp {
		width: 1rem;
		font-size: 0.24rem;
		color: #666666;
		text-align: center;
		float: left;
	}

	.topwordpdown {
		width: 1rem;
		font-size: 0.28rem;
		color: #666666;
		float: left;
		text-align: center;
		color: #C0153E;
		font-weight: bold;
	}

	.topwordq {
		float: left;
		width: 2.5rem;
		font-size: 0.24rem;
		color: #666666;
		text-align: center;
	}

	.topwordqdown {
		float: left;
		width: 2.5rem;
		font-size: 0.24rem;
		color: #333333;
		text-align: center;
	}

	.topwordc {
		float: left;
		width: 1.4rem;
		font-size: 0.24rem;
		color: #666666;
		text-align: center;
	}

	.topwordcdown {
		float: left;
		width: 1.4rem;
		font-size: 0.28rem;
		color: #333333;
		text-align: center;
	}

	.banner {
		width: 100%;
		height: 1.22rem;
		/* background-color: #333333; */
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
	}


	.bannerright {
		width: 6rem;
		height: 1.2rem;
		margin:0.2rem auto;
		overflow: hidden;
	}

	.bannerright img {
		width: 6rem;
		height: 1.2rem;
	}

	.bordernone {
		border-bottom: none;
	}
	.banner_top{
		width: 100%;
		height: 2.25rem;
	}
	
	@media screen and (max-width: 768px) {
		.lf_tab_box div {
		    width: 1.4rem;
		    height: 0.5rem;
		}



		.peoplebox {
			width: 7rem;
			margin-left: 0.25rem;
			overflow: hidden;
			padding-bottom: 0.2rem;
			margin-top: 0.1rem;
			border-bottom: none
		}

		.leftbox {
			width: 100%;
			overflow: hidden;
			float: left;
		}

		.leftpeople {
			width: 7rem;
			border-bottom: 1px solid rgba(239, 239, 239);
			padding-bottom: 0.2rem;
		}

		.lefttop {
			width: 6.9rem;
			height: 0.3rem;
			line-height: 0.3rem;
			margin-top: 0.2rem;
		}

		.banner {
			width: 100%;
			height: 0.75rem;
			margin-left: 0;
			margin-top: 0.3rem;
			margin-bottom: 0.3rem;
		}
		.changebox{
			margin-top: 0.5rem;
		}
	

		.sort {
			height: auto;
			width: 6.8rem;
			min-height: 0.7rem;
			font-size: 0.26rem;
			margin-left: 0.35rem;
			margin-top: 0.2rem;
			display: flex;
			align-items: center;
		}
		.sort span{
			width: 6rem;
			margin-right: 0.1rem;
			flex-grow: 1;
			flex-shrink: 0;
			display: flex;
			align-items: center;
			line-height: normal;
		}
		.sortbutton {
			display: none;
		}

		.sortbuttonsmall {
			display: block;
			width: 0.7rem;
			height: 0.7rem;
			background-color: #C0153E;
			border-radius: 0.04rem;
			float: right;
			color: #fff;

		}

		.sortleft {
			width: 0.4rem;
			height: 0.4rem;
			margin-top: 0.15rem;
			margin-left: 0.15rem;
			float: left;
		}

		.sortleft img {
			width: 0.4rem;
			height: 0.4rem;
		}

		.rightbox {
			width: 7.5rem;
			overflow: hidden;
			float: left;
			margin-left: 0;
			margin-top: 0.5rem;
		}

		.rightinfo {
			overflow: hidden;
			width: 7.5rem;
			background-image: linear-gradient(to bottom, #fafafa, #fff);
			padding-bottom: 0.2rem;
		}

		.itembox {
			width: 6.9rem;
			height: 2rem;
			border-left: 4px solid #C0153E;
			margin-top: 0.2rem;
			margin-left: 0.25rem;
			background-image: linear-gradient(to right, #fff, #FFF1F2);
			border-radius: 0 0.1rem 0.1rem 0;
			box-shadow: 0 0.01rem 0.1rem 0.01rem #999999;
		}

		.itemtitle {
			height: 0.5rem;
			width: 6.9rem;
			line-height: 0.5rem;
		}

		.itemtitleword {
			width: 6.4rem;
			height: 0.3rem;
			font-size: 0.2rem;
			color: #999999;
			text-align: right;
			margin-right: 0.3rem;
		}

		.itemrightpng {
			margin-top: -0.05rem;
		}

		.itemallbox {
			width: 6.7rem;
			height: 0.8rem;
			margin-top: 0.2rem;
		}

		.itemnum {
			width: 1.6rem;
			height: 1rem;
			line-height: 1rem;
			color: #C0153E;
			font-size: 0.3rem;
			text-align: center;
			float: right;
			font-weight: bold;
		}

		.bannerright {
			width: 7.5rem;
			height:1.5rem;
			margin-top: 0.2rem;
			margin-bottom: 0.2rem;
		}

		.bannerright img {
			width: 7.5rem;
			height: 1.5rem;
		}
		.banner_top{
			width: 100%;
			height: 0.88rem;
		}
		.leftdown{
			width:6.9rem;
		}
		.topwordq,.topwordqdown{
			width:3.1rem;
		}
		.topwordc,.topwordcdown{
			width:1.4rem;
		}
		.numinfo{
			width: 5.9rem;
		}
		.numword{
			width:1.4rem;
			text-align: center;
		}
	}
</style>